import * as React from 'react';
import { Button, Dialog, DialogBody, DialogFooter, InputGroup, Intent, MenuItem, Tooltip } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { EyeOff, EyeOpen, Lock } from '@blueprintjs/icons';
import './LoginDialog.css';

const LOGIN = 'I have an account';
const CREATE = 'I need an account';
const RESET = 'I forgot my password';

function LoginDialog({
    allowSignUp,
    action,
    changeAction,
    challenge,
    error,
    isOpen,
    onCancel,
    onClose,
    onChallengeResponse,
    onLogin,
    onReset,
    onResetVerification,
    onSignUp,
    onSignUpVerification,
    title,
    promptForVerificationCode
}) {

    // const [action, setAction] = React.useState(LOGIN);
    const [emailAddress, setEmailAddress] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordVerification, setPasswordVerification] = React.useState('');
    const [code, setCode] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const showPasswordButton = (
        <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`} >
            <Button
                disabled={false}
                icon={showPassword ? <EyeOpen /> : <EyeOff />}
                intent={showPassword ? Intent.WARNING : Intent.SUCCESS}
                minimal={true}
                onClick={() => setShowPassword(!showPassword)}
            />
        </Tooltip>
    );

    const sharedProps = { disabled: false };

    const clear = () => {
        setPassword('');
        setPasswordVerification('');
        setCode('');
    }

    const handleSubmit = data => {
        switch (action) {
            case LOGIN: {
                if (challenge) {
                    onChallengeResponse(data)
                } else {
                    onLogin(data);
                }
                break;
            }
            case CREATE:{
                if (code) {
                    onSignUpVerification(data);
                    setCode('');
                } else if (emailAddress && password && password === passwordVerification) {
                    onSignUp(data);
                }
                break;
            }
            case RESET: {
                if (code && password && password === passwordVerification) {
                    onResetVerification(data);
                    setCode('');
                } else if (emailAddress) {
                    onReset(data);
                }
                break;
            }
            default: {
                // Do nothing
            }
        }
    }

    const renderActions = (action, { handleClick, handleFocus, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={action}
                onClick={handleClick}
                onFocus={handleFocus}
                roleStructure="listoption"
                text={action}
            />
        );
    };

    const formIsValid = () => {
        switch (action) {
            case LOGIN: {
                return emailAddress && password;
            }
            case CREATE:{
                if (promptForVerificationCode) {
                    return emailAddress && code;
                } else {
                    return emailAddress && password && password === passwordVerification;
                }
            }
            case RESET: {
                if (promptForVerificationCode) {
                    return emailAddress && code && password && password === passwordVerification;
                } else {
                    return emailAddress;
                }
            }
            default: {
                return false;
            }
        }
    }

    const promptForPassord = () => {
        let prompt = true;
        if (action === RESET) {
            prompt = promptForVerificationCode && true;
        } else if (action === CREATE && promptForVerificationCode) {
            prompt = false;
        }

        return prompt;
    }

    const promptForPassordVerification = () => {
        let prompt = challenge;
        if (action === RESET && promptForVerificationCode) {
            prompt = true;
        } else if (action === CREATE && !promptForVerificationCode) {
            prompt = true;
        }

        return prompt;
    }

    const renderErrorMessage = () => {
        if ((action === CREATE || (action === RESET && promptForVerificationCode) || (action === LOGIN && challenge)) && password !== passwordVerification) {
            return 'Passwords do not match'
        } else if (error && (!challenge || (challenge && !password))) {
            return error;
        } else {
            return '';
        }
    }

    return (
        <Dialog
            className='LoginDialog-root'
            title={title ? title : action === CREATE ? 'Create an Account' : action === RESET ? 'Reset Password' : 'Log In'}
            icon={<Lock />}
            isOpen={isOpen === undefined ? true : isOpen}
            onClose={onClose || console.log('LoginDialog: onClose property is missing')}
            isCloseButtonShown={false}
            canOutsideClickClose={false}
        >
            <DialogBody>
                <div className='LoginDialog-body'>
                    {promptForVerificationCode ?
                        <>
                            <div className='LoginDialog-prompt'>
                                {promptForVerificationCode}
                            </div>
                            <InputGroup
                                className='LoginDialog-input'
                                {...sharedProps}
                                onChange={e => setCode(e.target.value)}
                                placeholder="Enter your verification code"
                                rightElement={showPasswordButton}
                                type={showPassword ? "text" : "password"}
                                value={code}
                            />
                        </>
                    :
                        <InputGroup
                            className='LoginDialog-input'
                            {...sharedProps}
                            onChange={e => setEmailAddress(e.target.value)}
                            placeholder="Enter your email address"
                            type='text'
                            value={emailAddress}
                        />
                    }
                    <>
                        {promptForPassord() ? <InputGroup
                            className='LoginDialog-input'
                            {...sharedProps}
                            onChange={e => setPassword(e.target.value)}
                            placeholder={challenge ? 'Enter a new password' : 'Enter your password'}
                            rightElement={showPasswordButton}
                            type={showPassword ? "text" : "password"}
                            value={password}
                        /> : <div className='LoginDialog-input'>{/* Empty, to to take vertical space */}</div>}
                        {promptForPassordVerification() ? <InputGroup
                            className='LoginDialog-input'
                            {...sharedProps}
                            onChange={e => setPasswordVerification(e.target.value)}
                            placeholder="Verify your password"
                            rightElement={showPasswordButton}
                            type={showPassword ? "text" : "password"}
                            value={passwordVerification}
                        /> : <div className='LoginDialog-input'>{/* Empty, to to take vertical space */}</div>}
                    </>                    
                    <div className='LoginDialog-error'>{renderErrorMessage()}</div>
                </div>
            </DialogBody>
            <DialogFooter
                children={
                    <Select
                        activeItem={action}
                        disabled={promptForVerificationCode}
                        itemRenderer={renderActions}
                        items={allowSignUp ? [LOGIN, CREATE, RESET] : [LOGIN, RESET]}
                        filterable={false}
                        onItemSelect={e => changeAction(e)}
                    >
                        <Button text={action} rightIcon="double-caret-vertical" />
                    </Select>
                }
                actions={
                    <div className='LoginDialog-buttons'>
                        <Button intent={Intent.DANGER} text="Cancel" 
                            onClick={onCancel || console.log('LoginDialog: onCancel property is missing')} />
                        <Button intent={Intent.PRIMARY} text='Submit'
                            disabled={!formIsValid()}
                            onClick={() => {
                                handleSubmit({ username: emailAddress, password, code });
                                clear();
                            }}
                        />
                    </div>
                }
            />
        </Dialog>
    );
};

export {
    LoginDialog,
    CREATE,
    LOGIN,
    RESET
}
import { Component } from 'react';
import { Modal, Table } from 'antd';
import TucuxiConfig from './TucuxiConfig.json';
import { Cody } from '../services/Cody'
import './CodyConversations.css';

export default class CodyConversations extends Component {

  constructor(props) {
    super(props);

    this.state = {
        count: 0,
        totalCount: 0,
        botsList: [],
        conversationList: [],
        conversationToView: null,
        waitingForResponse: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.getBots(), 1000);
  }

  getBots() {
    if (this.state.waitingForResponse) {
        return;
    }

    this.setState( { waitingForResponse: true },
        () => Cody.getBots(data => this.handleBotsReceived(data), err => this.handleError(err)));
}

  getCurrentConversations() {
    if (this.state.waitingForResponse) {
        return;
    }

    this.setState( { waitingForResponse: true },
        () => Cody.getConversations(data => this.handleConversationsReceived(data), err => this.handleError(err)));
  }

  handleBotsReceived(data) {
    this.setState({ botsList: data.data, waitingForResponse: false }, () => this.getCurrentConversations());
  }

  handleConversationsReceived(data) {
    const count = data && data.meta && data.meta.pagination && data.meta.pagination.count;
    const totalCount = data && data.meta && data.meta.pagination && data.meta.pagination.total;

    if (data) {
        this.setState({ count, totalCount, conversationList: data.data, waitingForResponse: false }, () =>
            setTimeout(() => this.getCurrentConversations(), TucuxiConfig.cody.conversationsPollPeriod));
    } else {
        console.log('Response contained no data!');
    }
  }

  handleError(err) {
    console.log(err);
  }

  viewConversationDetails(conversation) {
    this.setState({ conversationToView: conversation });
  }

  analyzeConversation(conversation) {
    console.log('analyzeConversation: ', conversation);
  }

  formatDate(seconds) {
    const date = new Date(seconds * 1000);
    return date.toLocaleString();
  }

  getBotName(id) {
    let botName;
    this.state.botsList.forEach(b => {
        if (b.id === id) {
            botName = b.name;
        }
    });

    return botName;
  }

  renderBotsTable(data) {
    const dataSource = [];
    
    data.forEach(b => {
        dataSource.push({
            key: b.id,
            ...b
        });
    });

    const columnDefs = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => this.formatDate(text),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    return <Table dataSource={dataSource} columns={columnDefs} />;
  }

  renderConversationTable(data) {
    const dataSource = [];
    
    data.forEach(c => {
        dataSource.push({
            key: c.id,
            ...c
        });
    });

    const columnDefs = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => this.formatDate(text),
        },
        {
            title: 'Topic',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Bot Name',
            dataIndex: 'bot_id',
            key: 'bot_id',
            render: text => this.getBotName(text),
        },
        {
            title: 'Conversation ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_, rec) => (
                <div className='conversations-table-actions'>
                    <button className='conversations-table-action' onClick={() => this.viewConversationDetails(rec)}>View</button>
                    {/* <button className='conversations-table-action' onClick={() => this.analyzeConversation(rec)}>Analyze</button> */}
                </div>
            )
        },
    ];

    return <Table dataSource={dataSource} columns={columnDefs} />;
  }

  render() {
    const conversation = this.state.conversationToView;
    return (<>
        <div className='tucuxi-app-header'>
            <a href='/'>
                <img 
                    id='tucuxi-app-header-logo'
                    src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                    alt='tucuxi.ai logo'
                />
            </a>
        </div>
        <div className='conversations-body'>
            <div className='conversations-table-title'>
                Bots:
            </div>
            <div className='bots-table-wrapper'>
                {this.renderBotsTable(this.state.botsList)}
            </div>
            <div className='conversations-table-title'>
                Conversations ({this.state.totalCount} total):
            </div>
            <div className='conversations-table-wrapper'>
                {this.renderConversationTable(this.state.conversationList)}
            </div>
        </div>
        <Modal
            footer={null}
            title={conversation && conversation.c_id}
            open={conversation}
            onOk={() => this.setState({ conversationToView: null })}
            onCancel={() => this.setState({ conversationToView: null })}
        >
            <p>{conversation && conversation.summary}</p>
        </Modal>
    </>);
  }
}

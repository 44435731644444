import { Component } from 'react';
import { Button, Form, Input, Result } from 'antd';
import { LOGIN, LoginDialog } from './LoginDialog'
import { Cognito } from '../services/Cognito';
import TucuxiConfig from './TucuxiConfig.json';
import './Tucuxi.css';

export default class Tucuxi extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codePrompt: null,
      loginAction: LOGIN,
      loginError: null,
      showLogin: false,
      IdToken: null,
      challenge: null,
      session: null,
      fullName: null,
      emailAddress: null,
      company: null,
      signUpFormSubmitted: false,
    }
  }

  componentDidMount() {
    if (TucuxiConfig.renderLeadCaptureForm) {
      const head = document.querySelector("head");
      const script = document.createElement("script");
  
      script.setAttribute("src", 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js');
      script.setAttribute("type", 'module');
      head.appendChild(script);
    }
  }

  handleLoginButtonPressed() {
    const userName = Cognito.getUser().userName;
    if (userName) {
      console.log('User ' + userName + ' is already logged-in.');
    } else {
      this.setState({ showLogin: true, loginAction: LOGIN, loginError: null })
    }   
  }

  handleLogoutButtonPressed() {
    Cognito.logout();
    this.setState({ IdToken: null });
  }

  handleSignUpFormSubmitted(e) {
    const formData = {
      fullName: this.state.fullName,
      emailAddress: this.state.emailAddress,
      company: this.state.company,
    };

    console.log('Sign-Up form submitted: ', formData);
    this.setState({ signUpFormSubmitted: true });

    // Below is supposed to help netlify identify form submissions:
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encodeFormData({ "form-name": "contact", ...formData })
    })
      .then(() => console.log("contact form sumbitted"))
      .catch(err => console.error(err));

    e.preventDefault();
  }

  encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  doCloseLoginDialog(token) {
    this.setState({
      codePrompt: null,
      loginError: null,
      showLogin: false,
      IdToken: token,
      challenge: null,
      session: null,
    });
  }
  
  doSubmitLogin(req) {
    Cognito.login(req.username, req.password)
      .then(res => {
        console.log('Login response: ', res);
        if (res) {
          if (res.IdToken) {
            this.doCloseLoginDialog(res.IdToken);
          } else if (res.ChallengeName) {
            this.setState({
              loginError: 'New password required',
              challenge: res.ChallengeName,
              session: res.Session,
            });
          } else {
            this.setState({
              loginError: 'Unknown response',
            });
          }
        } else {
          this.setState({
            loginError: 'Login error occurred',
          });
        }
      })
      .catch(err => {
        console.log('Login error: ', err);
        this.setState({
          loginError: err.message,
        });
    });
  }

  doSubmitChallengeResponse(req) {
    Cognito.respondToChallenge(this.state.challenge, this.state.session, req.username, req.password)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          this.setState({
            loginError: 'Unknown response',
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doSubmitSignup(req) {
    Cognito.create(req.username, req.password)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          const destination = res.CodeDeliveryDetails ? res.CodeDeliveryDetails.Destination : 'unknown';
          this.setState({
            codePrompt: 'Enter verification code sent to "' + destination + '"',
            loginError: null,
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doSubmitReset(req) {
    Cognito.resetPassword(req.username)
      .then(res => {
        console.log('Result: ', res);
        if (res && res.IdToken) {
          this.doCloseLoginDialog(res.IdToken);
        } else {
          const destination = res.CodeDeliveryDetails ? res.CodeDeliveryDetails.Destination : 'unknown';
          this.setState({
            codePrompt: 'Enter verification code sent to "' + destination + '"',
            loginError: null,
          });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
  }

  doVerifySignUpCode(req) {
    const result = Cognito.confirmSignUp(req.username, req.code);
    console.log('doVerifySignUpCode result:', result);
    if (result && typeof result === 'object') {
      result.then(r => {
        if (typeof r === 'string' && r.indexOf('failed') >= 0) {
          this.setState({ loginError: r });
        } else {
          this.setState({ loginError: null, codePrompt: null, loginAction: LOGIN });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
    }
  }

  doVerifyResetCode(req) {
    const result = Cognito.confirmReset(req.username, req.password, req.code);
    console.log('doVerifyResetCode result:', result);
    if (result && typeof result === 'object') {
      result.then(r => {
        if (typeof r === 'string' && r.indexOf('failed') >= 0) {
          this.setState({ loginError: r });
        } else {
          this.setState({ loginError: null, codePrompt: null, loginAction: LOGIN  });
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        this.setState({
          loginError: err.message,
        });
      });
    }
  }

  renderLogin() {
    return (
      <LoginDialog
        allowSignUp={TucuxiConfig.allowSignUp}
        action={this.state.loginAction}
        changeAction={loginAction => this.setState({ loginError: null, codePrompt: null, loginAction })}
        challenge={this.state.challenge}
        error={this.state.loginError}
        isOpen={true}
        onCancel={() => this.doCloseLoginDialog()}
        onClose={() => this.doCloseLoginDialog()}
        onChallengeResponse={req => this.doSubmitChallengeResponse(req)}
        onLogin={req => this.doSubmitLogin(req)}
        onReset={req => this.doSubmitReset(req)}
        onResetVerification={req => this.doVerifyResetCode(req)}
        onSignUp={req => this.doSubmitSignup(req)}
        onSignUpVerification={req => this.doVerifySignUpCode(req)}
        promptForVerificationCode={this.state.codePrompt}        
      />
    );
  }

  renderSignUpForm() {
    return (
      <Form name='contact'
        layout='vertical'
        requiredMark='optional'
        size='large'
        style={{
          maxWidth: 600,
          minWidth: 300
        }}
      >
        {/* A little help for the Netlify post-processing bots */}
        <input type="hidden" name="form-name" value="contact" />
        <Form.Item label="Full Name" required>
          <Input
            name='fullName'
            onChange={e => this.setState({ fullName: e.target.value })}
            placeholder="your full name"
            value={this.state.fullName}
          />
        </Form.Item>
        <Form.Item label="Email Address" required>
          <Input
            name='emailAddress'
            onChange={e => this.setState({ emailAddress: e.target.value })}
            placeholder="your email address"
            type='email'
            value={this.state.emailAddress}
          />
            </Form.Item>
        <Form.Item label="Company">
          <Input
            name='company'
            onChange={e => this.setState({ company: e.target.value })}
            placeholder="your company affiliation"
            value={this.state.company}
          />
        </Form.Item>
        <Form.Item>
          <Button className='signup-form-submit-button'
            disabled={!(this.state.fullName && this.state.emailAddress)}
            onClick={e => this.handleSignUpFormSubmitted(e)}
            type="primary"
          >
            Keep me informed!
          </Button>
        </Form.Item>
      </Form>
    );
  }

  renderFormSubmitAcknowledgement() {
    return (
      <div className='form-submit-acknowledgement'>
        <Result
          status="success"
          title="Congratulations, you're signed-up!"
          subTitle="We'll keep you informed of updates and opportunities to be among the first to try tucuxi.ai."
        />
      </div>
    );
  }

  render(params) {
    const loggedInUser = Cognito.getUser().userName;
    const TucuxiText = TucuxiConfig.text;
    return (
      <div className="App">
        <div className='tucuxi-app-header'>
          <a href='/'>
            <img 
                id='tucuxi-app-header-logo'
                src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                alt='tucuxi.ai logo'
            />
          </a>
          {TucuxiConfig.renderLoginButton && <div className='tucuxi-app-header-bar'>
            <div className='header-left-content'>
            </div>
            <div className='header-right-content'>
              <div className='login-button-container'>
                { loggedInUser
                  ? <Button className='tucuxi-app-login-button' type="primary" onClick={() => this.handleLogoutButtonPressed()}>Log Out</Button>
                  : <Button className='tucuxi-app-login-button' type="primary" onClick={() => this.handleLoginButtonPressed()}>Log In</Button>
                }
                <div className='header-logged-in-user'>{loggedInUser || 'guest'}</div>
              </div>
              </div>
          </div>}
        </div>
        <div className='tucuxi-page-text-primary'>
          <h2>{TucuxiText.intro.leadIn}</h2>
          <h1>{TucuxiText.site.name}</h1>
          <h2>{TucuxiText.description.short}</h2>
        </div>
        <div className='tucuxi-page-content'>
          {loggedInUser ? undefined : TucuxiConfig.renderLeadCaptureForm ?
            <zapier-interfaces-page-embed
              page-id='cm0jmjxi9000772tqe2ch53st'
              no-background='true'
              style={{ width: '500px', height: '720px'}}>
            </zapier-interfaces-page-embed> 
          : this.state.signUpFormSubmitted ? this.renderFormSubmitAcknowledgement() : this.renderSignUpForm()}
          {loggedInUser &&
            <iframe 
              title='Tucuxi Virtual Associate'
              src={`https://embed.cody.bot/${TucuxiConfig.bot.marilyn}`}
              style={{border: '0px'}}
              name="codyai"
              scrolling="no"
              frame-border="1"
              margin-height="0"
              margin-width="0"
              height="600px"
              max-width="1200px"
              width='90%'
              allowFullScreen>
            </iframe>}
        </div>
        {this.state.showLogin && this.renderLogin()}
      </div>
    );
  }
}

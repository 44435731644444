import * as React from 'react';
import './ClientIntake.css';

export default function ClientIntake() {

    const [showComparisonTable, setShowComparisonTable] = React.useState(false);

    return (<>
            <div className='tucuxi-app-header'>
                <a href='/'>
                    <img 
                        id='tucuxi-app-header-logo'
                        src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                        alt='tucuxi.ai logo'
                    />
                </a>
            </div>
            <div className='client-intake-body'>
                <header>
                    <h1>Meet Tucu: Your AI-Powered Legal Receptionist</h1>
                </header>

                <section>
                    <h2>Revolutionizing Legal Client Intake and Management</h2>
                    <p>Tucu is not just another answering service. It's an AI-powered legal receptionist that transforms how law firms handle client interactions, intake, and case evaluation.</p>
                </section>

                <section>
                    <h2>Key Features</h2>
                    <div className="feature">
                        <h3>Customizable Voice and Knowledge</h3>
                        <p>Configure Tucu with your firm's specific knowledge and choose a male, female, or even a cloned voice that matches your brand.</p>
                    </div>
                    <div className="feature">
                        <h3>Intelligent Client Intake</h3>
                        <p>Tucu conducts potential client intake, answering questions dynamically and gathering crucial information for your firm.</p>
                    </div>
                    <div className="feature">
                        <h3>AI-Powered Case Analysis</h3>
                        <p>Our advanced AI analyzes client information, identifies potential claims, and provides confidence levels on case success probability.</p>
                    </div>
                    <div className="feature">
                        <h3>Actionable Dashboard</h3>
                        <p>Get a real-time view of all calls with a stack-ranked list of potentially successful cases, allowing you to prioritize your focus efficiently.</p>
                    </div>
                    <div className="feature">
                        <h3>Seamless Scheduling</h3>
                        <p>Tucu integrates with your firm's calendar, scheduling appointments based on attorney availability and case priority.</p>
                    </div>
                </section>

                <section>
                    <h2>Why Tucu Outperforms the Competition</h2>
                    <p>Unlike traditional answering services or basic chatbots, Tucu offers:</p>
                    <ul>
                        <li>True AI-powered conversation, not just scripted responses</li>
                        <li>Detailed case analysis and success probability estimation</li>
                        <li>Customizable voice and knowledge base specific to your firm</li>
                        <li>Intelligent prioritization of potential cases</li>
                        <li>Seamless integration with your existing systems</li>
                    </ul>
                    <button id="showComparison" onClick={() => setShowComparisonTable(!showComparisonTable)}>
                        {showComparisonTable ? 'Hide Comparison' : 'See Full Comparison'}
                    </button>
                    <table id="comparisonTable" style={{display: showComparisonTable ? 'table' : 'none' }}>
                        <tbody>
                            <tr>
                                <th>Feature</th>
                                <th>Tucu</th>
                                <th>Traditional Services</th>
                                <th>Basic Chatbots</th>
                            </tr>
                            <tr>
                                <td>AI-Powered Conversations</td>
                                <td>✅</td>
                                <td>❌</td>
                                <td>Limited</td>
                            </tr>
                            <tr>
                                <td>Case Analysis</td>
                                <td>✅</td>
                                <td>❌</td>
                                <td>❌</td>
                            </tr>
                            <tr>
                                <td>Customizable Voice</td>
                                <td>✅</td>
                                <td>Limited</td>
                                <td>❌</td>
                            </tr>
                            <tr>
                                <td>24/7 Availability</td>
                                <td>✅</td>
                                <td>✅</td>
                                <td>✅</td>
                            </tr>
                            <tr>
                                <td>Case Prioritization</td>
                                <td>✅</td>
                                <td>❌</td>
                                <td>❌</td>
                            </tr>
                            <tr>
                                <td>System Integration</td>
                                <td>✅</td>
                                <td>Limited</td>
                                <td>Limited</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <h2>Ready to Transform Your Client Intake?</h2>
                    <p>Experience the future of legal client management with Tucu. Book a demo today and see how our AI-powered solution can streamline your operations and boost your success rate.</p>
                    <a href="mailto:info@tucuxi.ai" className="cta-button">Book a Demo</a>
                </section>

                <footer>
                    <p>&copy; 2024 Tucuxi, Inc. All rights reserved.</p>
                </footer>
            </div>
        </>
    );
};

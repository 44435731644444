import {
  ForgotPasswordCommand,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";

import aws from "./awsConfig.json";

const client = new CognitoIdentityProviderClient({ region: aws.region });

/**
 * Get the currently authenticated user information
 * 
 * @returns user
 */
const getUser = () => {
  let user = {};
  if (inBrowser()) {
    user = {
      userName: sessionStorage.getItem("userName"),
      idToken: sessionStorage.getItem("idToken"),
      accessToken: sessionStorage.getItem("accessToken"),
      refreshToken: sessionStorage.getItem("refreshToken"),
    }
  }

  return user;
}

const setAuthenticatedUser = (emailAddress, result) => {
  console.log('login successful for user ' + emailAddress);
  if (inBrowser()) {
    sessionStorage.setItem("userName", emailAddress);
    sessionStorage.setItem("idToken", result.IdToken);
    sessionStorage.setItem("accessToken", result.AccessToken);
    sessionStorage.setItem("refreshToken", result.RefreshToken);
  } else {
    console.warn('sessionStorage is undefined, possibly the page is not yet rendered/mounted');
  }
}

/**
 * Logout
 */
const logout = () => {
  if (inBrowser()) {
    sessionStorage.clear();
  }
}

/**
 * Login with AWS Cognito service
 * 
 * @param {*} emailAddress 
 * @param {*} password 
 * @throws
 * @returns {AuthenticationResultType}
 */
const login = async (emailAddress, password) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: aws.clientId,
    AuthParameters: {
      USERNAME: emailAddress,
      PASSWORD: password,
    },
  };

  const command = new InitiateAuthCommand(params);
  const response = await client.send(command);
  if (response) {
    console.log('Cognito authentication result:', response)
    if (response.AuthenticationResult) {
      setAuthenticatedUser(emailAddress, response.AuthenticationResult);
    } else if (response.ChallengeName) {
      console.log(response.ChallengeName + ' for user ' + emailAddress);
      sessionStorage.clear();
    } else {
      console.log('login failed for user ' + emailAddress);
      sessionStorage.clear();
    }
  }

  return response.AuthenticationResult || response;
};

/**
 * Respond to an authorization challenge (such as NEW_PASSWORD_REQUIRED) with AWS Cognito service
 * 
 * @param {*} challengeName 
 * @param {*} session
 * @param {*} emailAddress 
 * @param {*} newPassword
 * @throws
 * @returns {RespondToAuthChallengeCommand}
 */
const respondToChallenge = async (challengeName, session, emailAddress, newPassword) => {
  const params = {
    ClientId: aws.clientId,
    ChallengeName: challengeName,
    ChallengeResponses: {
      USERNAME: emailAddress,
      NEW_PASSWORD: newPassword,
    },
    Session: session,
  };

  const command = new RespondToAuthChallengeCommand(params);
  const response = await client.send(command);
  if (response) {
    console.log('Cognito challenge result:', response)
    if (response.AuthenticationResult) {
      setAuthenticatedUser(emailAddress, response.AuthenticationResult);
    } else if (response.ChallengeName) {
      console.log(response.ChallengeName + ' for user ' + emailAddress);
      sessionStorage.clear();
    } else {
      console.log('challenge failed for user ' + emailAddress);
      sessionStorage.clear();
    }
  }

  return response.AuthenticationResult || response;
};

/**
 * Create a new account with AWS Cognito service
 * 
 * @param {*} emailAddress 
 * @param {*} password
 * @throws
 * @returns {SignUpCommandOutput}
 */
const create = async (emailAddress, password) => {
  const params = {
    ClientId: aws.clientId,
    Username: emailAddress,
    Password: password,
    UserAttributes: [
      {
        Name: "email",
        Value: emailAddress,
      },
    ],
  };

  const command = new SignUpCommand(params);
  const response = await client.send(command);

  return response;
};

/**
 * Confirm account with AWS Cognito service and 2FA code
 * 
 * @param {*} emailAddress 
 * @param {*} code 
 * @throws
 * @returns {boolean}
 */
const confirmSignUp = async (emailAddress, code) => {
  const params = {
    ClientId: aws.clientId,
    Username: emailAddress,
    ConfirmationCode: code,
  };

  const command = new ConfirmSignUpCommand(params);
  const result = await client.send(command);
  console.log('confirmSignUp result:', result);
};

/**
 * Request a password reset
 * 
 * @param {*} emailAddress 
 * @throws
 * @returns {ForgotPasswordResponse}
 */
const resetPassword = async (emailAddress) => {
  const params = {
    ClientId: aws.clientId,
    Username: emailAddress,
  };

  const command = new ForgotPasswordCommand(params);
  const response = await client.send(command);

  return response;
};

/**
 * Confirm account with AWS Cognito service and 2FA code
 * 
 * @param {*} emailAddress 
 * @param {*} password 
 * @param {*} code 
 * @throws
 * @returns {boolean}
 */
const confirmReset = async (emailAddress, password, code) => {
  const params = {
    ClientId: aws.clientId,
    Username: emailAddress,
    ConfirmationCode: code,
    Password: password,
  };

  const command = new ConfirmForgotPasswordCommand(params);
  const result = await client.send(command);
  console.log('confirmReset result:', result);
};

const inBrowser = () => typeof sessionStorage !== `undefined`;

export const Cognito = {
  client,
  confirmSignUp,
  confirmReset,
  create,
  getUser,
  login,
  logout,
  resetPassword,
  respondToChallenge
};
